.nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-height: 12vh;
  align-items: center;
  background: #222;
}

#nav-title {
  color: #D5E2EE;
}

nav h3 {
  margin: 0;
  text-align: left;
  color: lightblue;
}

.nav-links {
  min-width: 300px;
  display: flex;
  justify-content: space-between;
  list-style: none;
  color: black;
  text-decoration: none;
  margin-right: 10%;
}

.nav-links-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 5%;
}

.nav-link {
  padding: 0 10 0 10;
  color: #D5E2EE;
  text-decoration: none;
  font-size: 1.3em;
}

#navbarNav a {
  padding: 10px 16px;
}

#navbarNav a:hover {
  background-color: #111;
}

#nav-title {
  font-size: 1.1em;
}