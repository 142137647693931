.bg-fixed {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.home-welcome {
  height: 85vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../images/tabletop-crop.jpg');
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: multiply;
}

#home-welcome-overlay {
  text-shadow: 5px 5px 10px #151106;
}

#home-welcome-overlay h1 {
  font-size: 3em;
  font-weight: bolder;
}

#home-welcome-overlay p {
  font-size: 2em;
  font-weight: bolder;
}

.bg-cdf {
  background: #cdf;
}

.text-cdf {
  color: #cdf;
}

.bg-grad-cdf-t {
  background: linear-gradient(to top, white, #cdf);
}

.bg-grad-cdf-tl {
  background: linear-gradient(to top left, white, #cdf);
}

.bg-grad-cdf-tr {
  background: linear-gradient(to top right, white, #cdf);
}

.bg-grad-cdf-bl {
  background: linear-gradient(to bottom left, white, #cdf);
}

.bg-grad-cdf-br {
  background: linear-gradient(to bottom right, white, #cdf);
}

.spotlight {
  box-shadow: 4px 4px 10px black, 5px 5px 15px #cdf;
}

.tsection p {
  font-size: 1.2em;
}

.ccm h1 {
  color: #0DCAF0;
  /* color: #cdf; */
}

.ccm h4 {
  color: #0DCAF0;
  /* color: #cdf; */
}

.ccm p {
  color: #cdf;
}

.secdiv {
  height: 10vh;
  /* background: linear-gradient(to bottom, #cdf, #eee, #eee, #cdf); */
  background: linear-gradient(to bottom, #ddd, #F8F9FA, #F8F9FA, #ddd);
}

.flash {
  background-color: #0DCAF0;
  background-image: linear-gradient(-30deg, darkslategray, #dff, darkslategray);
}

.flame {
  background-color: #0DCAF0;
  background-image: linear-gradient(to top, #90f, #35f, cyan, white);
}

.fgrad {
  background-color: white;
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
}