.bg-fixed-2 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}
  
.pricing-header {
  height: 40vh;
  width: 100%;
  background-image: url('../images/rack_169.jpg');
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: multiply;
}

#pricing-header-overlay {
  text-shadow: 5px 5px 10px #151106;
}

#pricing-header-overlay * {
  font-size: 1.5em;
  font-weight: bolder;
}
